module.exports = global.config = {
    login: {
        welcome: {
            en: "Welcome",
            no: "Velkommen"
        },
    },

    server: {
        connection: {
            //url: "https://localhost:7286",
            url: "https://lesdetapi.azurewebsites.net"
        },
    }
};
