import React, { useState } from 'react';
import Loader from "../components/global-components/loader.js";
import PageHeader from '../components/global-components/page-header';
import Footer from '../components/global-components/footer-v2';
import Comments from '../components/comments/comments';
import "../components/comments/comments.css";
import InfiniteScroll from 'react-infinite-scroller';
import { newsItemClicked as newsItemClickedApi } from '../api';
import "../components/scrollTopButton/scrollTopButton.css";

const accordion = (target) => {
    var panel = target.nextElementSibling;
    if (panel.style.display === "block") {
        panel.style.display = "none";
    } else {
        panel.style.display = "block";
    }
};

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    let mybutton = document.getElementById("myBtn");

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const SearchComponent = ({ timeFixer, title }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const [serchResultCount, setserchResultCount] = useState(0);

    let inputHandler = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        setQuery(lowerCase);
    };

    const searchData = async () => {
        if (query.length < 3) {
            return;
        }
        setLoading(true);
        await fetch(global.config.server.connection.url + "/Search?category=all&startRow=" + data.length + "&query=" + query)
            .then((res) => res.json())
            .then((result) => {
                console.log("RESULT: ", result);
                setData(result);
            });

        setLoading(false);

        await fetch(global.config.server.connection.url + "/Search/count?category=all&startRow=0&query=" + query)
            .then((res) => res.json())
            .then((result) => {
                setserchResultCount(result);
            });

        document.getElementById("searchResultCounter").style.display = "block";
    };

    const loadmore = async () => {
        let startRow = data.length + 1;
        console.log("Start Row: ", startRow);
        if (data.length > 0) {
            await fetch(global.config.server.connection.url + "/Search?category=all&startRow=" + startRow + "&query=" + query)
                .then((res) => res.json())
                .then((result) => {
                    setData((data) => [...data, ...result]);
                });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchData();
        }
    };

    return (
        <>
            {loading && (
                <Loader />
            )}

            <PageHeader headertitle={title} />

            <div className="post-area banner-post-area pt-4 pd-bottom-70">
                <div className="container">
                    <button onClick={topFunction} id="myBtn" title="Go to top">Til toppen</button>

                    <div className="row">
                        <div className="col-lg-6">
                            <i className="fa fa-info-circle" style={{ color: '#097BED' }} />
                            <i style={{ paddingTop: 10 + 'px', display: 'inline', color: '#1C3459' }} className="title"> Søk på tvers av alle nyhetskilder.<span style={{ fontSize: '14px', color: '#1C3459' }}> ( minimum tre bokstaver )</span></i>

                            <br />
                            {/* <p>Fra dato:</p>
                            <p>Til dato:</p> */}
                            <br />
                            <div className="single-input-wrap input-group">
                                <input type="text" value={query} onChange={inputHandler} onKeyDown={handleKeyDown} className="form-control" placeholder="Hva søker du etter?" />
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fa fa-search" /></div>
                                </div>
                            </div>

                            <div className="submit-area">
                                <button onClick={searchData} className="btn btn-base">Søk</button>
                            </div>
                            <br />
                            <strong id="searchResultCounter" style={{ display: 'none', fontSize: '20px', color: '#097BED' }}> Ditt søk ga {serchResultCount} treff</strong>
                            <hr />
                        </div>
                    </div>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadmore}
                        hasMore={loading}
                        loader={
                            <div className="loader" key={0}>
                                <Loader />
                            </div>
                        }
                    >
                        <div className="row">
                            {data.map((news, i) => {
                                return (
                                    <div key={news.id} className="col-lg-6">
                                        <div className="hot-news-3">
                                            <div className="single-post-list-wrap style-two">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={news.imageUrl} alt="img" style={{ objectFit: 'cover', height: 200 + 'px' }} loading="lazy" />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <div className="details">
                                                            <div className="post-meta-single">
                                                                <ul>
                                                                    <li><b style={{ color: '#097BED' }}>{news.source.toUpperCase()} </b></li>
                                                                    -<li><b style={{ color: '#097BED' }}><i className="fa fa-clock-o" />{timeFixer(news.publishedDate)}</b></li>
                                                                    -<li><b style={{ color: '#097BED' }}>{news.category.toUpperCase()}</b></li>
                                                                </ul>
                                                            </div>
                                                            <h6 style={{ paddingTop: '10px' }} className="title" onClick={(e) => newsItemClickedApi(news)}>
                                                                <a target="_blank" rel="noopener noreferrer" href={news.link}>{news.headline}</a>
                                                            </h6>
                                                            <p style={{ paddingTop: '10px' }} className="title" onClick={(e) => newsItemClickedApi(news)}>
                                                                <a target="_blank" rel="noopener noreferrer" href={news.link}>{news.description}</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <button className="accordion" onClick={(e) => accordion(e.target)}>
                                                    <span style={{ pointerEvents: 'none' }}>
                                                        <i className="fa fa-comment" aria-hidden="true"></i>
                                                        <p style={{ display: "inline", paddingLeft: 15 + 'px' }}>{news.nrOfComments} kommentarer</p>
                                                    </span>
                                                </button>
                                                <div className='panel'>
                                                    <Comments currentUserId="1" newsItemId={news.id} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SearchComponent;
