export const getNewsItems = async (category, startFrom) => {

    const data = await fetch(global.config.server.connection.url + "/Nyheter?category="+category+"&startRow="+startFrom, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log('result from API: ',result)
					return result
				},
				(error) => {
					console.log("ERROR!")
					console.log(error)
					this.setState({
						error,
						loading: false,
					});
				}
			)
    return data
};

export const getMostClickedNewsItems = async (category) => {
    try {
        const response = await fetch(global.config.server.connection.url + "/Nyheter/GetMostClickedNews?category=" + category, {
            method: "GET",
            headers: {
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result;
        
    } catch (error) {
        console.error("Error fetching most clicked news items:", error);
        throw error; // Rethrow the error after logging it
    }
};


export const newsItemClicked = (newsItem) => {
        let formData = new FormData();
        formData.append("NewsItemId", newsItem.id)
       
    
        fetch(global.config.server.connection.url + "/Click", {
            method: "POST",
            headers: {
                'Accept': 'application/json'
            },
            body: formData,
        })
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result)
                  
                },
                (error) => {
                    console.log('An error occured: ', error)
                }
            )
};