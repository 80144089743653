import React, { Component, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom"; //import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import Navbar from './components/global-components/navbar-v2';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import Login from './components/global-components/login';
import './config';
import Loader from './components/global-components/loader';
import SearchComponent from './Pages/searchPage';

const NewsComponent = lazy(() => import("./Pages/newsPage"));
const ValgomatCompoent = lazy(() => import("./Pages/valgomatPage"));
// const MostViewed = lazy(() => import("./components/mostViewed/mostViewed"));
// const SearchNewsComponent = lazy(() => import("./Pages/searchComponent"));

const monthNames = ["JANUAR", "FEBRUAR", "MARS", "APRIL", "MAI", "JUNI",
	"JULI", "AUGUST", "SEPTEMBER", "OKTOBER", "NOVEMBER", "DESEMBER"
];

class Root extends Component {

	constructor(props) {
		super(props)

		this.state = {
			show: true,
		}


	}

	componentDidMount() {
		// this.getUserIPAdress();
	}



	timeFixer(publishedDate) {

		var dato = new Date(publishedDate)
		dato.setHours(dato.getHours() +4); //dato.setHours(dato.getHours() + 2); suddenly created news 2 hrs in the future
		var hours = dato.getHours()

		if (hours < 9) {
			hours = "0" + dato.getHours()
		}
		var minutes = dato.getMinutes()
		if (minutes < 10) {
			minutes = "0" + dato.getMinutes()
		}

		return dato.getDate() + ". " + monthNames[dato.getMonth()] + " - " + hours + ":" + minutes
	}


	render() {

		return (
			<GoogleOAuthProvider clientId="301023520951-hdeo7stkatvh70gjsek4smi87t2n8mro.apps.googleusercontent.com">

				<HashRouter basename="/">
					{/* style for hide/sticky menu */}
					{/* <div style={{marginTop: 170+'px'}}>  */}
					<div>

						{/* <Login /> */}
						<Switch>
						<Route exact path="/">
							<>
								<Navbar />
								<Suspense fallback={<Loader />}>
									{/* <MostViewed category='all' /> */}
									<NewsComponent category='all' timeFixer={this.timeFixer} title='Alle nyheter' />
								</Suspense>
							</>
							</Route>

							<Route exact path="/nyheter" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='nyheter' timeFixer={this.timeFixer} title='Nyheter' />
									</Suspense>
								</>
							)} />
							<Route exact path="/sport" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='sport' timeFixer={this.timeFixer} title='Sport' />
									</Suspense>

								</>
							)} />
							<Route exact path="/okonomi" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='økonomi' timeFixer={this.timeFixer} title='Økonomi' />
									</Suspense>

								</>
							)} />
							<Route exact path="/kultur" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='kultur' timeFixer={this.timeFixer} title='Kultur' />
									</Suspense>
								</>
							)} />
							<Route exact path="/meninger" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='meninger' timeFixer={this.timeFixer} title='Meninger' />
									</Suspense>
								</>
							)} />
							<Route exact path="/forbruker" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='forbruker' timeFixer={this.timeFixer} title='Forbruker' />
									</Suspense>
								</>
							)} />
							<Route exact path="/teknologi" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='teknologi' timeFixer={this.timeFixer} title='Teknologi' />
									</Suspense>
								</>
							)} />
							<Route exact path="/offentlig" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='offentlig' timeFixer={this.timeFixer} title='Offentlig informasjon' />
									</Suspense>
								</>
							)} />
							<Route path="/flestkommentarer" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='flestKommentarer' timeFixer={this.timeFixer} title='Flest kommentarer' />
									</Suspense>
								</>
							)} />
							<Route path="/sistekommentarer" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='sisteKommentarer' timeFixer={this.timeFixer} title='Siste kommentarer' />
									</Suspense>
								</>
							)} />
							<Route path="/soknyheter" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<SearchComponent timeFixer={this.timeFixer} title='Søk i nyheter' />
									</Suspense>
								</>
							)} />

							<Route path="/utenlandske" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<NewsComponent category='utenlandske' timeFixer={this.timeFixer} title='Utenlandske aviser' />
									</Suspense>
								</>
							)} />

							<Route path="/minstemme" replace component={() => (
								<>
									<Navbar />
									<Suspense fallback={<Loader />}>
										<ValgomatCompoent title='Min Stemme' />
									</Suspense>
								</>
							)} />


						</Switch>
					</div>
				</HashRouter>
			</GoogleOAuthProvider>
		)
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('nextpage'));
