import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from "./loader.js";
// import Ticker from 'react-ticker';
// import WarningTicker from '../warnings/warningTicker'
// import Error from './components/error';

const monthNames = ["januar", "februar", "mars", "april", "mai", "juni",
	"juli", "august", "september", "oktober", "november", "desember"
];

const weekdays = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];

class NavbarV2 extends Component {

	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			loadingWeather: false,
			loadingCity: false,
			loadingPowerPrices: false,
			loadingIpAdress: false,
			weather: null,
			weatherSymbol: null,
			city: '',
			currentPowerPrice: 0,
			powerRegion: null,
			ipAdress: null,
		}

		this.getweather = this.getweather.bind(this);
		this.loadTopBanner = this.loadTopBanner.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		if (this.state.temperature == null) {
			this.loadTopBanner();
		}
	}
	async loadTopBanner() {

		await fetch("https://geolocation-db.com/json/", {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(result)
					this.setState({
						ipAdress: result.IPv4,
						loadingIpAdress: false,
					})
				},
				(error) => {
					console.log("ERROR!")
					console.log(error)
					this.setState({
						error,
						loadingIpAdress: false
					});
				}
			)


		navigator.geolocation.getCurrentPosition(async function (position) {
			//  console.log(position.coords.latitude)
			//  console.log(position.coords.longitude)

			//load weathersymbol
			await fetch("https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=" + position.coords.latitude + "&lon=" + position.coords.longitude, {
				method: "GET",
				headers: {
					'Accept': 'application/json'
				},
			})
				.then(res => res.json())
				.then(
					(result) => {
						//console.log('Result from weatherAPI: ', result)
						this.setState({
							weatherSymbol: result.properties.timeseries[0].data.next_1_hours.summary.symbol_code,
							weather: result.properties.timeseries[0].data.instant.details,
							loadingWeather: false,
						})

					},
					(error) => {
						console.log("ERROR!")
						console.log(error)
						this.setState({
							error,
							loadingWeather: false
						});
					}
				)

			//google
			//await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + position.coords.latitude + "," + position.coords.longitude + "&sensor=true&key=AIzaSyDt2sNgcWjSO3UkUo0I73BD6017Y2RCX9g", {
			//https://www.bigdatacloud.com/packages/free-api
			//hente city
			await fetch("https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=" + position.coords.latitude + "&longitude=" + position.coords.longitude + "&localityLanguage=en", {
				method: "GET",
				headers: {
					'Accept': 'application/json'
				},
			})
				.then(res => res.json())
				.then(
					(result) => {
						// console.log('Original city request: ', result)
						this.setState({
							//city: result.results[0].address_components[2].short_name,
							city: result.city,
							loadingCity: false,
						})
					},
					(error) => {
						console.log("ERROR!")
						console.log(error)
						this.setState({
							error,
							loadingCity: false,
						});
					}
				)
			const d = new Date();
			let day = d.getDate();
			let month = d.getMonth() + 1
			if(month.toString().length === 1)
			{
				month = '0' + month.toString();
			}
			if(day.toString().length === 1)
			{

				day = '0' + day;
			}
			let hour = d.getHours();

			//hent regionkode (NO1, NO2...) for spotpris basert på by.
			await fetch(global.config.server.connection.url + "/Power?city=" + this.state.city, {
			//await fetch(global.config.server.connection.url + "/Power?city=Moss", {
				method: "GET",
				headers: {
					Accept: 'application/json',
				},
			})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							powerRegion: result
						})
						// console.log('Power region: ',result);
					}).catch(err => {
						console.log("ERROR: ", err);
					})


			//hent strømpris for gitt regionkode (N01, N02...) TODO: this.state.powerRegion i stedet for hardkoding av region under
			await fetch("https://www.hvakosterstrommen.no/api/v1/prices/" + d.getFullYear() + "/" + month + "-" + day + "_" + this.state.powerRegion + ".json", {
				method: "GET",
				headers: {
					'Accept': 'application/json'
				},
			})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							currentPowerPrice: result[hour].NOK_per_kWh * 1.25,
							loadingPowerPrices: false,
						})

					},
					(error) => {
						console.log("ERROR!")
						console.log(error)
						this.setState({
							error,
							loadingPowerPrices: false,
							currentPowerPrice: 0,
						});
					}
				)

		}.bind(this),
			function (error) {
				if (error.code === error.PERMISSION_DENIED)
				this.setState({
					loadingWeather: false,
					loadingCity: false,
				})

			}.bind(this));
	}


	getweather() {
		if (this.state.weather == null)
			return;

		var d = new Date();
		var hour = d.getHours();

		var weatherSumbolUrl = ''
		
		if (hour > 6 && hour < 17)
			weatherSumbolUrl = 'https://lesdet.no/assets/img/weather/' + this.state.weatherSymbol + '.png'
		else
			weatherSumbolUrl = 'https://lesdet.no/assets/img/weather/' + this.state.weatherSymbol + '.png'

		return (
			<>
				<a href="https:www.pent.no" target="top" style={{cursor: 'hand'}}>
					<li style={{ color: 'white', width: 50 + 'px' }}> <img src={weatherSumbolUrl} alt="img" /> </li>
					<li style={{ color: 'white' }}> {this.state.weather.air_temperature} °C </li><br></br>
				</a>
				<li style={{ color: 'white' }}> <i className="fa fa-plug" style={{ paddingRight: 5 + 'px' }} />
					Spotprisen nå: NOK {Math.round(this.state.currentPowerPrice * 100)} øre pr. kWh <br></br>
				</li>
			</>
		)
	}

	today() {
		var d = new Date()
		return " " + weekdays[d.getDay()] + " " + d.getDate() + ". " + monthNames[d.getMonth()]
	}

	handleClick = (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
    };

	render() {

		const { loadingWeather, loadingCity, loadingPowerPrices, loadingIpAdress } = this.state;

		if (loadingWeather || loadingCity || loadingPowerPrices || loadingIpAdress) {
			return (
				<div className="content">
					<Loader />
				</div>
			)
		}

		//console.log('IP Adress : ',this.state.ipAdress)

		let publicUrl = process.env.PUBLIC_URL + '/'
		//let imgattr = 'logo'
		//let anchor = '#'
		return (
			<div id="navbar" className="navbar-area">
				{/* topbar end*/}
				<div className="topbar-area">
					<div className="container">
						<div className="row justify-content-center">
							{/* <div className="col-lg-6 col-md-7 align-self-center">
								<div className="topbar-menu text-md-left text-center">
									<ul className="align-self-center">
								<li><Link to="/author">Author</Link></li>
								<li><Link to="/cat-page">Tech News</Link></li>
								<li><Link to="/cat-fashion">Fashion</Link></li>
								<li><Link to="/cat-life-style">Life Style</Link></li>
								</ul>
								</div>
							</div> */}
							<div className="col-lg-6 col-md-5 text-md-right text-center mt-2 mt-md-0">
								<div className="topbar-social">
								{/* <WarningTicker></WarningTicker> */}
									{/* <div className="topbar-date d-none d-lg-inline-block"><i className="fa fa-calendar" /> {this.today()}</div>
							<div className="topbar-date d-none d-lg-inline-block"><i className="fa fa-home" />
							<a href="#"><span onClick={this.setStartPage}>Sett som startside</span></a>
							</div> */}
									{/* <div className="topbar-date d-none d-lg-inline-block"><i className="fa fa-calendar" /> {this.today()}</div> */}

									<ul className="social-area social-area-2">
										<li style={{ color: 'white' }}><i className="fa fa-calendar" />{this.today()}</li>
									</ul>
									<br></br>

									<ul className="social-area social-area-2">

										<li style={{ color: 'white' }}>{this.state.city}</li>
										{this.getweather()}
										{/* <li><a className="facebook-icon" href="https://www.facebook.com/solverwp/"><i className="fa fa-facebook" /></a></li>
										<li><a className="twitter-icon" href="https://www.twitter.com/solverwp/"><i className="fa fa-twitter" /></a></li>
										<li><a className="youtube-icon" href="https://www.youtube.com/solverwp/"><i className="fa fa-youtube-play" /></a></li>
										<li><a className="instagram-icon" href="https://www.instagram.com/solverwp/"><i className="fa fa-instagram" /></a></li>
										<li><a className="google-icon" href="https://www.google.com/solverwp/"><i className="fa fa-google-plus" /></a></li> */}
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>
				{/* topbar end*/}
				{/* adbar end*/}
				<div className="adbar-area d-none d-lg-block">
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-lg-5 align-self-center">
								<div className="logo text-md-left text-center">
									<Link className="main-logo" to="/"><img src={publicUrl + "assets/img/logo-2.png"} alt="img" /></Link>
								</div>
							</div>
							<div className="col-xl-6 col-lg-7 text-md-right text-center">
								<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4654718041658074" crossOrigin="anonymous"></script>
								<ins className="adsbygoogle"
									style={{display:'block'}}
									data-ad-client="ca-pub-4654718041658074"
									data-ad-slot="1885527814"
									data-ad-format="auto"
									data-full-width-responsive="true"></ins>
								<script>
									(adsbygoogle = window.adsbygoogle || []).push({});
								</script>
							</div>
						</div>
					</div>
				</div>
				{/* adbar end*/}
				{/* navbar start */}
				<nav className="navbar navbar-expand-lg">
					<div className="container nav-container">
						<div className="responsive-mobile-menu">

							<div className="logo d-lg-none d-block">
								<Link className="main-logo" to="/"><img src="assets/img/logo-mobile.png" alt="img" /></Link>
							</div>
							<button className="menu toggle-btn d-block d-lg-none" data-target="#nextpage_main_menu" aria-expanded="false" aria-label="Toggle navigation">
								<span className="icon-left" />
								<span className="icon-right" />
							</button>
						</div>
						<div className="nav-right-part nav-right-part-mobile">

							{/* <a className="search header-search" href="#"><i className="fa fa-search" /></a> */}
						</div>

						<div className="collapse navbar-collapse" id="nextpage_main_menu">
							<ul className="navbar-nav menu-open">
								{/* <li className="menu-item current-menu-item">
									<Link to="/flestklikk">Flest klikk</Link>
								</li> */}
								<li className="menu-item-has-children current-menu-item">
									<a href="#" onClick={this.handleClick}>Kategorier</a>
									<ul className="sub-menu">
										<li><Link to="/nyheter">Nyheter</Link></li>
										<li><Link to="/sport">Sport</Link></li>
										<li><Link to="/okonomi">Økonomi</Link></li>
										<li><Link to="/kultur">Kultur</Link></li>
										<li><Link to="/meninger">Meninger</Link></li>
										{/* <li><Link to="/forbruker">Forbruker</Link></li> */}
										<li><Link to="/teknologi">Teknologi</Link></li>
										<li><Link to="/offentlig">Offentlig</Link></li>
									</ul>
								</li>
								<li className="menu-item-has-children current-menu-item">
									<a href="#" onClick={this.handleClick}>Kommentarer</a>
									<ul className="sub-menu">
										<li><Link to="/sistekommentarer">Sist kommentert</Link></li>
										<li><Link to="/flestkommentarer">Flest kommentarer</Link></li>
									</ul>
								</li>
								<li className="menu-item current-menu-item">
									<Link to="/soknyheter">Søk i nyheter</Link>
								</li>
								{/* <li className="menu-item current-menu-item">
									<Link to="/utenlandske">Utenlandske</Link>
								</li> */}
								<li className="menu-item current-menu-item">
									<Link to="/minstemme">Min stemme</Link>
								</li>
								{/* 
								<li className="menu-item current-menu-item">
								<Link to="/weather">Været</Link>
								</li> */}
								{/* <li className="menu-item-has-children current-menu-item">
									<Link to="#">Aviser</Link>
									<ul className="sub-menu">
										<li><Link to="/">ABC Nyheter</Link></li>
										<li><Link to="/home-v1">Aftenposten</Link></li>
										<li><Link to="/home-v2">Dagbladet</Link></li>
										<li><Link to="/home-v3">Nettavisen</Link></li>
										<li><Link to="/home-v3">NRK</Link></li>
										<li><Link to="/home-v3">TV2</Link></li>
										<li><Link to="/home-v3">VG</Link></li>
									</ul>
								</li>
								<li className="menu-item-has-children current-menu-item">
									<a href="#">Pages</a>
									<ul className="sub-menu">
										<li><Link to="/error">Error</Link></li>
										<li><Link to="/author">Author</Link></li>
										<li><Link to="/blog">Blog</Link></li>
										<li><Link to="/blog-details">Blog Details</Link></li>
									</ul>
								</li>
								<li className="menu-item-has-children current-menu-item">
									<Link to="/blog">Blog</Link>
									<ul className="sub-menu">
										<li><Link to="/blog">Blog</Link></li>
										<li><Link to="/blog-details">Blog Details</Link></li>
									</ul>
								</li> */}
							</ul>
						</div>
						{/* <div className="nav-right-part nav-right-part-desktop">
				        <div className="menu-search-inner">
				          <input type="text" placeholder="Search For" />
				          <button type="submit" className="submit-btn"><i className="fa fa-search" /></button>
				        </div>
				      </div> */}
					</div>
				</nav>
			</div>

		)
	}
}


export default NavbarV2