export const getComments = async (newsItemId) => {

    const data = await fetch(global.config.server.connection.url + "/Comment?newsItemId="+newsItemId.newsItemId, {
        method: "GET",
        headers: {
            'Accept': 'application/json'
        },
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result
            },
            (error) => {
                console.log(error)
            }
        )
    return data
};


export const createComment = async (text, parentId = null, newsItemId) => {

    console.log("createComment - text:", text, ", parentId:", parentId , ", newsItemId:", newsItemId)

    let formData = new FormData();
    formData.append("ParentId", parentId)
    formData.append("body", text)
    formData.append("userId", 1)
    formData.append("username", "Anonym")
    formData.append("newsItemId", newsItemId)

    const data = await fetch(global.config.server.connection.url + "/Comment", {
        method: "POST",
        headers: {
            'Accept': 'application/json'
        },
        body: formData,
    })
        .then(res => res.json())
        .then(
            (result) => {
                return {
                    id: result,
                    body: text,
                    parentId: parentId,
                    userId: "1",
                    username: "Anonym",
                    createdAt: new Date().toISOString(),
                  };
            },
            (error) => {
                console.log('An error occured: ', error)
            }
        )
    return data

    // return {
    //     id: Math.random().toString(36).substr(2, 9),
    //     body: text,
    //     parentId,
    //     userId: "1",
    //     username: "Mattis",
    //     createdAt: new Date().toISOString(),
    //   };

  
};

export const updateComment = async (text) => {
  return { text };
};

export const deleteComment = async () => {
  return {};
};


export const likeClicked = (commentItemId, action, value) => {
    let formData = new FormData();
    formData.append("commentItemId", commentItemId)
    formData.append("action", action)
    formData.append("value", value)
    
    fetch(global.config.server.connection.url + "/Like", {
        method: "POST",
        headers: {
            'Accept': 'application/json'
        },
        body: formData,
    })
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
            },
            (error) => {
                console.log('An error occured: ', error)
            }
        )
};

export const dislikeClicked = (commentItemId, action, value) => {
    let formData = new FormData();
    formData.append("commentItemId", commentItemId)
    formData.append("action", action)
    formData.append("value", value)
    
    fetch(global.config.server.connection.url + "/Like", {
        method: "POST",
        headers: {
            'Accept': 'application/json'
        },
        body: formData,
    })
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
            },
            (error) => {
                console.log('An error occured: ', error)
            }
        )
};

