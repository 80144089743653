import React from 'react';
import CommentForm from './commentForm';
import LikeForm from './likeForm';

const Comment = ({
    comment,
    replies,
    setActiveComment,
    activeComment,
    updateComment,
    deleteComment,
    addComment,
    parentId = null,
    currentUserId,
  }) => {
    const isEditing =
      activeComment &&
      activeComment.id === comment.id &&
      activeComment.type === "editing";
    const isReplying =
      activeComment &&
      activeComment.id === comment.id &&
      activeComment.type === "replying";
    const fiveMinutes = 300000;
    const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
    const canDelete =
      currentUserId === comment.userId && replies.length === 0 && !timePassed;
    const canReply = Boolean(currentUserId);
    const canEdit = currentUserId === comment.userId && !timePassed;
    const replyId = parentId ? parentId : comment.id;
    const createdAt = new Date(comment.createdAt).toLocaleDateString('no-NB', {
        month: '2-digit',day: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit'});

    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
      <div key={comment.id} className="comment">
        <div className="comment-image-container">
            <img src={publicUrl + "assets/img/user-icon.png"} alt='user icon'></img>
        </div>
        <div className="comment-right-part">
          <div className="comment-content">
            <div className="comment-author">{comment.username}</div>
            <div><p>{createdAt}</p></div>
          </div>
          {!isEditing && <div className="comment-text">{comment.body}</div>}
          {isEditing && (
            <CommentForm
              submitLabel="Oppdater"
              hasCancelButton
              initialText={comment.body}
              handleSubmit={(text) => updateComment(text, comment.id)}
              handleCancel={() => {
                setActiveComment(null);
              }}
            />
          )}
          <div className="comment-actions">
            {canReply && (
              <div
                className="comment-action"
                onClick={() =>
                  setActiveComment({ id: comment.id, type: "replying" })
                }
              >
                <p>Svar</p>
              </div>
            )}
            {canEdit && (
              <div
                className="comment-action"
                onClick={() =>
                  setActiveComment({ id: comment.id, type: "editing" })
                }
              >
                Endre
              </div>
            )}
            {canDelete && (
              <div
                className="comment-action"
                onClick={() => deleteComment(comment.id)}
              >
                Slett
              </div>
            )}
          </div>
          {isReplying && (
            <CommentForm
              submitLabel="Svar"
              handleSubmit={(text) => addComment(text, replyId)}
            />
          )}
          
          <LikeForm
            commentItemId={comment.id}
            commentLikes={comment.likes}
            commentDislikes={comment.dislikes}
          />
          
          {replies.length > 0 && (
            <div className="replies">
              {replies.map((reply) => (
                <Comment
                  comment={reply}
                  key={reply.id}
                  setActiveComment={setActiveComment}
                  activeComment={activeComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  addComment={addComment}
                  parentId={comment.id}
                  replies={[]}
                  currentUserId={currentUserId}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

export default Comment

