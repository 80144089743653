import React from "react";


class Loader extends React.Component {
  render() {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <div className="loading-text">Laster...</div>
    </div>

    );
  }
}

export default Loader;