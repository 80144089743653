import React  from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getComments as getCommentsApi, createComment as createCommentApi, deleteComment as deleteCommentApi, updateComment as updateCommentApi } from './api';
import Comment from './comment';
import CommentForm from './commentForm';
import "./comments.css";


const Comments = ({currentUserId, newsItemId }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  //const [newsItemId, setNewsItemId] = useState(null);

  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null
  );

  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

  const addComment = (text, parentId) => {
    createCommentApi(text, parentId, newsItemId).then((comment) => {
      setBackendComments([comment, ...backendComments]);
      setActiveComment(null);
    });
  };

  const updateComment = (text, commentId) => {
    updateCommentApi(text).then(() => {
      const updatedBackendComments = backendComments.map((backendComment) => {
        if (backendComment.id === commentId) {
          return { ...backendComment, body: text };
        }
        return backendComment;
      });
      setBackendComments(updatedBackendComments);
      setActiveComment(null);
    });
  };
  const deleteComment = (commentId) => {
    if (window.confirm("Sikker på at du vil slette din kommentar?")) {
      deleteCommentApi().then(() => {
        const updatedBackendComments = backendComments.filter(
          (backendComment) => backendComment.id !== commentId
        );
        setBackendComments(updatedBackendComments);
      });
    }
  };

  // const addLike = (commentItemId) => {
  //   console.log('Liking comment', commentItemId)
  // };
  // const addDisLlike = (commentItemId) => {
  //   console.log('Disliking Comment', commentItemId)
  // };

  useEffect(() => {
    getCommentsApi({newsItemId}).then((data) => {
        if (data == null){
            return
        }
        setBackendComments(data);
    });
  }, []);

  return (
    <div className="comments">
      <h3 className="comments-title">Kommentarer</h3>
      <div className="comment-form-title">Skriv kommentar</div>
      <CommentForm submitLabel="Send" handleSubmit={addComment} />
      <div className="comments-container">
        {rootComments.map((rootComment) => (
          <Comment
            key={rootComment.id}
            comment={rootComment}
            replies={getReplies(rootComment.id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            currentUserId={currentUserId}
            newsItemId={newsItemId}
          />
        ))}
      </div>
    </div>
  );
};

export default Comments;

