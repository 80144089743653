import React, { useState, useEffect } from 'react';
import "./likes.css";
import { likeClicked as likeClickedApi } from './api';
import { dislikeClicked as dislikeClickedApi } from './api';

const LikeForm = ({ commentItemId, commentLikes, commentDislikes }) => {
    // Log initial props for debugging
    console.log('Initial props:', { commentItemId, commentLikes, commentDislikes });

    // Ensure commentLikes and commentDislikes are numbers
    const initialLikeCount = Number(commentLikes) || 0;
    const initialDislikeCount = Number(commentDislikes) || 0;

    const [likeCount, setLikeCount] = useState(initialLikeCount);
    const [dislikeCount, setDislikeCount] = useState(initialDislikeCount);
    const [activeBtn, setActiveBtn] = useState("none");

    useEffect(() => {
        // Log state values for debugging
        console.log('State values:', { likeCount, dislikeCount, activeBtn });
    }, [likeCount, dislikeCount, activeBtn]);

    const handleLikeClick = () => {
        console.log('Like clicked:', { activeBtn });
        if (activeBtn === "none") {
            likeClickedApi(commentItemId, 'like', 1);
            setLikeCount(prevCount => prevCount + 1);
            setActiveBtn("like");
            return;
        }

        if (activeBtn === 'like') {
            likeClickedApi(commentItemId, 'like', -1);
            setLikeCount(prevCount => prevCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn === "dislike") {
            likeClickedApi(commentItemId, 'like', 1);
            dislikeClickedApi(commentItemId, 'dislike', -1);
            setLikeCount(prevCount => prevCount + 1);
            setDislikeCount(prevCount => prevCount - 1);
            setActiveBtn("like");
        }
    };

    const handleDisikeClick = () => {
        console.log('Dislike clicked:', { activeBtn });
        if (activeBtn === "none") {
            dislikeClickedApi(commentItemId, 'dislike', 1);
            setDislikeCount(prevCount => prevCount + 1);
            setActiveBtn("dislike");
            return;
        }

        if (activeBtn === 'dislike') {
            dislikeClickedApi(commentItemId, 'dislike', -1);
            setDislikeCount(prevCount => prevCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn === "like") {
            dislikeClickedApi(commentItemId, 'dislike', 1);
            likeClickedApi(commentItemId, 'like', -1);
            setDislikeCount(prevCount => prevCount + 1);
            setLikeCount(prevCount => prevCount - 1);
            setActiveBtn("dislike");
        }
    };

    return (
        <div>
            <span onClick={handleLikeClick}>
                <p className={`btn ${activeBtn === "like" ? "like-active" : ""}`} style={{ display: "inline", paddingRight: '30px' }}>
                    <i className="fa fa-thumbs-o-up" style={{ paddingRight: '5px' }} />Liker &nbsp;
                    {likeCount}
                </p>
            </span>
            <span onClick={handleDisikeClick}>
                <p className={`btn ${activeBtn === "dislike" ? "dislike-active" : ""}`} style={{ display: "inline" }}>
                    <i className="fa fa-thumbs-o-down" style={{ paddingRight: '5px' }} />Liker ikke &nbsp;
                    {dislikeCount}
                </p>
            </span>
        </div>
    );
};

export default LikeForm;
